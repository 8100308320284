<template>
  <page-content>
    <a-tabs tab-position="left">

      <a-tab-pane key="1" tab="用户协议">
        <rich-text-field v-model="companyInfo.userAgreement" />
        <a-button style="margin-top: 10px" type="primary" block @click="saveUserAgreement">保存</a-button>
      </a-tab-pane>

      <a-tab-pane key="2" tab="隐私政策">
        <rich-text-field v-model="companyInfo.privacyPolicy"  />
        <a-button style="margin-top: 10px" type="primary" block @click="savePrivacyPolicy">保存</a-button>
      </a-tab-pane>

      <!--        <a-tab-pane key="3" tab="公司简介">-->
      <!--          <rich-text-field v-model="companyInfo.introduction"  />-->
      <!--          <a-button style="margin-top: 10px" type="primary" block @click="saveIntroduction">保存</a-button>-->
      <!--        </a-tab-pane>-->

      <!--        <a-tab-pane key="4" tab="全国热线">-->
      <!--          <a-input  v-model="companyInfo.contact" />-->
      <!--          <a-button style="margin-top: 10px" type="primary" block @click="saveContact">保存</a-button>-->
      <!--        </a-tab-pane>-->

    </a-tabs>


  </page-content>

</template>
<script>

  import { mapState } from 'vuex'

export default {
  data () {
    return {
      companyInfo: {
        userAgreement:"",
        privacyPolicy:"",
        introduction:"",
        contact:""
      }
    }
  },
  created () {
    this.$get('company').then((r) => {
      let map = {}
      r.data.forEach(item=>{
        map[item.type] = item.content
      })
      this.companyInfo = map
    })
  },
  computed: {
    ...mapState({
      systemName: state => state.setting.systemName,
      logo: state => state.setting.logo,
    })
  },
  methods: {
    saveUserAgreement(){
      this.save("userAgreement",this.companyInfo.userAgreement)
    },
    savePrivacyPolicy(){
      this.save("privacyPolicy",this.companyInfo.privacyPolicy)
    },
    saveIntroduction(){
      this.save("introduction",this.companyInfo.introduction)
    },
    saveContact(){
      this.save("contact",this.companyInfo.contact)
    },
    save (type,content,extra="") {
      this.$post('company', {
        type,content,extra
      }).then((r) => {
        this.$message.success("保存成功")
      })
    }
  }
}

</script>
<style>

</style>
